import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Div100vh from 'react-div-100vh'
import MediaQuery from 'react-responsive'

import logo from '../images/logo.svg'
import logoSmall from '../images/logo-small.svg'

import showreel from '../videos/showreel.mp4'
import showreelWide from '../videos/showreel-wide.mp4'
import showreelMobile from '../videos/showreel-mobile.mp4'

class IndexPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      infoActive: false
    }
    this.navToggle = this.navToggle.bind(this)
  }

  navToggle () {
    this.setState({
      infoActive: !this.state.infoActive
    })
  }
  componentWillMount () {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }
  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }
  handleClick = (e) => {
    if (!this.node.contains(e.target)) {
      this.setState({
        infoActive: false
      })
    }
  }
  render () {
    return (
      <Layout>
        <SEO
          title="We are Multitudes"
        />
        <article ref={node => this.node = node} className = {this.state.infoActive ? 't-index is-overlay' : 't-index'}>
          <Div100vh>
            <MediaQuery query="(min-width: 768px)">
              <img src={logo} alt="We are Multitudes" className="t-index__logo" />
            </MediaQuery>
            <MediaQuery query="(max-width: 767px)">
              <img src={logoSmall} alt="We are Multitudes" className="t-index__logo" />
            </MediaQuery>
            <div className="t-index__wrapper">
              <div className="t-index__info">
                <button className="t-index__info-btn" onClick = {this.navToggle}>info</button>
              </div>
              <div className="t-index__showreel">
                <MediaQuery query="(min-width: 768px)">
                  <MediaQuery query="(orientation: landscape)">
                    <video src={showreelWide} className="t-index__showreel-media" autoPlay muted playsInline loop></video>
                  </MediaQuery>
                  <MediaQuery query="(orientation: portrait)">
                    <video src={showreel} className="t-index__showreel-media" autoPlay muted playsInline loop></video>
                  </MediaQuery>
                </MediaQuery>
                <MediaQuery query="(max-width: 767px)">
                  <MediaQuery query="(orientation: landscape)">
                    <video src={showreel} className="t-index__showreel-media" autoPlay muted playsInline loop></video>
                  </MediaQuery>
                  <MediaQuery query="(orientation: portrait)">
                    <video src={showreelMobile} className="t-index__showreel-media" autoPlay muted playsInline loop></video>
                  </MediaQuery>
                </MediaQuery>
              </div>
              <div className="t-index__footer">
                <p className="t-index__footer-txt"><span>We are now part of</span> <a href="http://www.twocreate.com">Two Create Studio</a></p>
              </div>
              <div className="t-index__overlay">
                <button className="t-index__overlay-close" onClick = {this.navToggle}>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32">
                    <path
                      d="
                        M26.304 2.368l-11.371 11.371-11.371-11.371-2.261 2.261 11.371 11.371-11.371 11.371 2.261 2.261
                        11.371-11.371 11.371 11.371 2.261-2.261-11.371-11.371 11.371-11.371-2.261-2.261z
                        "
                    ></path>
                  </svg>
                  <span className="u-visually-hide">Close info</span>
                </button>
                <div className="t-index__overlay-content-container">
                  <p className="t-index__overlay-content">
                    Multitudes was founded in 2013 by 4 senior creatives, Chris and Thomas Cester in Melbourne, Australia and Lucy and Tobie Snowdowne in London, England.
                    A common set of values brought the partners together and between them they amassed innovative, effective and highly crafted work for brands including Givenchy,
                    Heinz, Möet & Chandon, No7, Belvedere Vodka, IBM, M&C Saatchi, Puig, Lucy McRae, Flavour Makers & Toni&Guy.
                    <br/><br/>
                    In 2018 Multitudes became part of Two Create Studio, retaining it’s core in London and office in Melbourne.
                    New collaborations can be seen on <a href="http://www.twocreate.com">twocreate.com</a>
                  </p>
                  <div className="t-index__overlay-locations">
                    <div className="t-index__overlay-location">
                      London<br/>
                      +44 20 7682 3313<br/>
                      <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#116;&#119;&#111;&#099;&#114;&#101;&#097;&#116;&#101;&#046;&#099;&#111;&#046;&#117;&#107;">
                        &#104;&#101;&#108;&#108;&#111;&#064;&#116;&#119;&#111;&#099;&#114;&#101;&#097;&#116;&#101;&#046;&#099;&#111;&#046;&#117;&#107;
                      </a>
                    </div>
                    <div className="t-index__overlay-location">
                      Melbourne<br/>
                      +61 401 766 322<br/>
                      <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#116;&#119;&#111;&#099;&#114;&#101;&#097;&#116;&#101;&#046;&#099;&#111;&#109;&#046;&#097;&#117;">
                        &#104;&#101;&#108;&#108;&#111;&#064;&#116;&#119;&#111;&#099;&#114;&#101;&#097;&#116;&#101;&#046;&#099;&#111;&#109;&#046;&#097;&#117;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Div100vh>
        </article>
      </Layout>
    )
  }
}

export default IndexPage
